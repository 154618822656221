/* eslint-disable array-callback-return */
import React ,{Component} from 'react';


import JobItem from './job-item/job-item';

import './work-item.scss';




class WorkItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
    
    }
  }

  render() {

    return (
      <>
      <section className="work__item">
        <div className="work__item_title">
        <ul className="work__manager_list" >
          <JobItem activeJob= {this.props.activeJob}
                   activeJobBtn={this.props.activeJobBtn}
                   activeButtonIndex = {this.props.activeButtonIndex}
                   upData={this.props.upData}
                   data = {this.props.data} 
                   activeIndex= {this.props.activeIndex}
                   listItem={this.props.listItem} 
                   fetchDataSoldTitle={this.props.fetchDataSoldTitle} 
                   editStatus = {this.props.editStatus}
                   mediaBtnEdit = {this.props.mediaBtnEdit}
                   />
                   
        </ul>
        </div>
      </section>
      </>
    );
  }
}

export default WorkItem;