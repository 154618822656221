import WorkItem from "./work-item/work-item"
import { Component } from "react";
import "./work-nav.scss"



class  WorkNav extends Component {
    constructor(props) {
        super(props);
        this.state ={
            
        }
    }
      // // Метод для обробки змін у this.props.data

    render() {
        return (
            <div className={this.props.mediaBtn ? "work__nav-active":"work__nav"}>
                <div className="work__nav-item">Skrót nazwy klienta w-g PBM</div>
                <WorkItem upData={this.props.upData} 
                            editStatus = {this.props.editStatus}
                            fetchDataSoldTitle={this.props.fetchDataSoldTitle} 
                         activeJobBtn={this.props.activeJobBtn}
                          listItem={this.props.listItem} 
                            data = {this.props.data} 
                            activeButtonIndex = {this.props.activeButtonIndex} 
                            activeIndex= {this.props.activeIndex}
                            mediaBtnEdit={this.props.mediaBtnEdit}/>
            </div>
        )
    }
   
}
export default WorkNav;
